import classnames from "classnames";
import React from "react";
import { useTranslation } from "@frontend/app/hooks";

import Anchor from "../../../components/Anchor";
import Text from "../../../components/Text";

type ContactItemsProps = {
  phone: string;
  phoneText: string;
  email: string;
  emailText: string;
};

const ContactItems: React.FC<ContactItemsProps> = ({ phone, phoneText, email, emailText }) => {
  return (
    <ul className={classnames("contact__items", "customer-contact__items")}>
      <li className={classnames("contact__item", "customer-contact__item")}>
        <Anchor href={`tel:${phone}`}>{phoneText}</Anchor>
      </li>

      <li className={classnames("contact__item", "customer-contact__item")}>
        <Anchor href={`mailto:${email}`}>{emailText}</Anchor>
      </li>
    </ul>
  );
};

const Contact: React.FC = () => {
  const translation = useTranslation();
  return (
    <footer aria-label="contact details" className={classnames("contact", "customer-contact")}>
      <div className={classnames("contact__section", "customer-contact__container")}>
        {translation("CUSTOMER_CONTACT_HEADER")?.trim() ? (
          <h2 className="contact__title customer-contact__header">
            <Text translationKey="CUSTOMER_CONTACT_HEADER" />
          </h2>
        ) : null}

        <div className={classnames("contact__group", "customer-contact__group")}>
          {translation("CUSTOMER_CONTACT_TITLE_1")?.trim() ? (
            <h3 className={classnames("contact__sub-title", "customer-contact__title")}>
              <Text translationKey="CUSTOMER_CONTACT_TITLE_1" />
            </h3>
          ) : null}

          <ContactItems
            phone={translation("CUSTOMER_CONTACT_PHONE_1")}
            phoneText={translation("CUSTOMER_CONTACT_PHONE_1")}
            email={translation("CUSTOMER_CONTACT_EMAIL_1")}
            emailText={translation("CUSTOMER_CONTACT_EMAIL_1")}
          />
        </div>

        <div className={classnames("contact__group", "customer-contact__group")}>
          {translation("CUSTOMER_CONTACT_TITLE_2")?.trim() ? (
            <h3 className={classnames("contact__sub-title", "customer-contact__title")}>
              <Text translationKey="CUSTOMER_CONTACT_TITLE_2" />
            </h3>
          ) : null}

          <ContactItems
            phone={translation("CUSTOMER_CONTACT_PHONE_2")}
            phoneText={translation("CUSTOMER_CONTACT_PHONE_2")}
            email={translation("CUSTOMER_CONTACT_EMAIL_2")}
            emailText={translation("CUSTOMER_CONTACT_EMAIL_2")}
          />
        </div>
      </div>
    </footer>
  );
};

export default Contact;
