import React from "react";
import parseHTML from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import { usePlaceholderResolver } from "../hooks";

type Props = {
  children: React.ReactNode;
  className?: string;
  href: string;
};

const Anchor: React.FC<Props> = ({ className, children: baseChildren, href: baseHref }) => {
  const placeholderResolver = usePlaceholderResolver();
  const [children, href] = placeholderResolver([baseChildren, baseHref]);
  const htmlText = sanitizeHtml(children);

  return htmlText ? (
    <a className={className} href={href}>
      {parseHTML(htmlText)}
    </a>
  ) : null;
};

export default Anchor;
