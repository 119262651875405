import classnames from "classnames";
import React from "react";
import { useTranslation } from "@frontend/app/hooks";

import Logo from "../../../components/Logo";

type Props = {
  merchantName: string;
};

const Header: React.FC<Props> = ({ merchantName }) => {
  const translation = useTranslation();
  return (
    <header aria-label="main navigation" className="header">
      <div className={classnames("header__section", "header__container")}>
        <Logo
          className={classnames("header__logo_image")}
          logoUrl={translation("MAIN_HEADER_ICON")}
          merchantName={merchantName}
        />
      </div>
    </header>
  );
};

export default Header;
