import { useSelector, shallowEqual } from "react-redux";
import React from "react";
import { RootState } from "@frontend/app/store/reducers";
import "@frontend/app/styles/dunning/theme.less";

import LayoutSection from "../../components/LayoutSection";
import Contact from "./Contact";
import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";

type Props = {
  children: React.ReactElement;
};

const DunningLayout: React.FC<Props> = ({ children }) => {
  const { merchantName } = useSelector(mapState, shallowEqual);
  return (
    <LayoutSection>
      <Header merchantName={merchantName} />
      <Body>{children}</Body>
      <Contact />
      <Footer merchantName={merchantName} />
    </LayoutSection>
  );
};

function mapState({ viewData: { merchant } }: RootState) {
  return { merchantName: merchant?.name ?? "" };
}

export default DunningLayout;
