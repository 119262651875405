import React, { ReactNode } from "react";
import Alert from "../../../components/Alert";

type Props = {
  children: ReactNode;
};

const Body: React.FC<Props> = ({ children }) => {
  return (
    <div className="body" suppressHydrationWarning>
      <Alert />
      <div className="body__section">{children}</div>
    </div>
  );
};

export default Body;
