import classnames from "classnames";
import React from "react";
import { useTranslation } from "@frontend/app/hooks";

import Logo from "../../../components/Logo";
import Text from "../../../components/Text";

type Props = {
  merchantName: string;
};

const Items: React.FC = () => {
  const translation = useTranslation();
  return (
    <nav aria-label="bottom navigation">
      <ul className={classnames("footer__items", "footer__links__items")}>
        <li className={classnames("footer__item", "footer__links__item first")}>
          <a
            className={classnames("footer__link", "footer__links__item__link")}
            href={translation("FOOTER_LINKS_IMPRINT_HREF")}
          >
            <Text translationKey="FOOTER_LINKS_IMPRINT" />
          </a>
        </li>
        <li className={classnames("footer__item", "footer__links__item last")}>
          <a
            className={classnames("footer__link", "footer__links__item__link")}
            href={translation("FOOTER_LINKS_LEGAL_HREF")}
          >
            <Text translationKey="FOOTER_LINKS_LEGAL" />
          </a>
        </li>
      </ul>
    </nav>
  );
};

const Footer: React.FC<Props> = ({ merchantName }) => {
  const translation = useTranslation();

  return (
    <footer aria-label="navigation footer" className={classnames("footer")}>
      <div className={classnames("footer__section", "footer__container")}>
        <div className={classnames("footer__links", "footer__links")}>
          <Items />
        </div>

        <div className={classnames("footer__logo", "footer__logo_image")}>
          <Logo
            className={classnames("logo_image__icon")}
            logoUrl={translation("FOOTER_LOGO")}
            merchantName={merchantName}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
